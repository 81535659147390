import saveAs from 'file-saver';
import swal from 'sweetalert2';
import {
  CHECK_MODULE,
  CHECK_MODULE_SUCCEEDED,
  CHECK_UNIT,
  CHECK_UNIT_SUCCEEDED,
  CLEAN_FILTERS_REQUEST,
  CLEAR_PAGANTIS_ORDER,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILED,
  DELETE_JUSTIFICANTE_TRANSFE_FAILED,
  DELETE_JUSTIFICANTE_TRANSFE_SUCCEEDED,
  DOC_DOWNLOAD_REQUEST,
  DOC_DOWNLOAD_REQUEST_FAILED,
  DOC_DOWNLOAD_REQUEST_SUCCEEDED,
  DOC_REQUEST,
  DOC_REQUEST_FAILED,
  DOC_REQUEST_SUCCEEDED,
  ENROLLMENT_TRANSACTION_TRANSFER_UPDATES,
  ENROLLMENT_TRANSACTION_TRANSFER_UPDATES_SUCCEEDED,
  FILTERS_REQUEST,
  FILTERS_REQUEST_FAILED,
  FILTERS_REQUEST_HOLD,
  FILTERS_REQUEST_SUCCEEDED,
  GET_TRANSACTION_PDF_SUCCEEDED,
  MOVE_TRANSACTION_MODULE,
  MOVE_TRANSACTION_MODULE_ERROR,
  MOVE_TRANSACTION_MODULE_SUCCEEDED,
  PAYMENT_CHARGE,
  PAYMENT_FAILED,
  PAYMENT_MAIL,
  PAYMENT_MARK_AS_PAID,
  PAYMENT_MARK_AS_PENDING,
  PAYMENT_SUCCEEDED,
  PAYMENT_UPDATE_DEADLINE,
  REMOVE,
  REMOVE_SELECTIONS,
  REMOVE_SUCCEEDED,
  REMOVE_TRANSACTION,
  REMOVE_TRANSACTION_FAILED,
  REQUEST,
  REQUEST_DETAIL,
  REQUEST_DETAIL_FAILED,
  REQUEST_DETAIL_SUCCEEDED,
  REQUEST_ENROLLMENT_ACTIVATION,
  REQUEST_ENROLLMENT_ACTIVATION_FAILED,
  REQUEST_ENROLLMENT_ACTIVATION_SUCCEEDED,
  REQUEST_ENROLLMENT_CHANGE_METHODOLOGY,
  REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_FAILED,
  REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_SUCCEEDED,
  REQUEST_ENROLLMENT_CHECK_METHODOLOGY,
  REQUEST_ENROLLMENT_CHECK_METHODOLOGY_FAILED,
  REQUEST_ENROLLMENT_CHECK_METHODOLOGY_SUCCEEDED,
  REQUEST_ENROLLMENT_EXPORT,
  REQUEST_ENROLLMENT_EXPORT_SUCCEEDED,
  REQUEST_ENROLLMENT_GRADE_EXPORT,
  REQUEST_ENROLLMENT_GRADE_EXPORT_SUCCEEDED,
  REQUEST_ENROLLMENT_HOLD,
  REQUEST_ENROLLMENT_HOLD_FAILED,
  REQUEST_ENROLLMENT_HOLD_SUCCEEDED,
  REQUEST_ENROLLMENT_SERVICES,
  REQUEST_ENROLLMENT_SERVICES_FAILED,
  REQUEST_ENROLLMENT_SERVICES_SUCCEEDED,
  REQUEST_FAILED,
  REQUEST_GET_ALL_FOR_STUDENT,
  REQUEST_GET_ALL_FOR_STUDENT_FAILED,
  REQUEST_GET_ALL_FOR_STUDENT_SUCCEEDED,
  REQUEST_MODIFY,
  REQUEST_MODIFY_FAILED,
  REQUEST_MODIFY_SUCCEEDED,
  REQUEST_PAGANTIS_ORDER,
  REQUEST_PAGANTIS_ORDER_FAILED,
  REQUEST_PAGANTIS_ORDER_SUCCEEDED,
  REQUEST_SUCCEEDED,
  SET,
  SET_DISCOUNT_COST_TRANSACTION,
  SET_MODULE_COST_TRANSACTION,
  SET_MODULE_TRANSACTION,
  SET_SELECTION,
  SET_TRANSACTION_PENALTY,
  SET_TRANSACTION_UPDATE_PRICES,
  SET_TRANSACTION_UPDATE_PRICES_FAILED,
  SET_TRANSACTION_UPDATE_PRICES_SUCCEEDED,
  SET_UNIT_COST_TRANSACTION,
  SET_UNIT_TRANSACTION,
  TAB_CHANGE,
  UPDATE,
  UPDATE_JUSTIFICANTE_TRANSFE_FAILED,
  UPDATE_JUSTIFICANTE_TRANSFE_SUCCEEDED,
  UPDATE_SUCCEEDED,
  REQUEST_ENROLMENT_TRANSFER_ESPERA,
  REQUEST_ENROLMENT_TRANSFER_ESPERA_SUCCEEDED,
  REQUEST_ENROLMENT_TRANSFER_ESPERA_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  data: [],
  selections: [],
  loading: false,
  alexiaLoading: false,
  alexiaResetLoadingId: null,
  pagantisOrder: null,
  redirectTo: '',
  reload: false,
};

const enrollmentsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REMOVE_TRANSACTION:
    case CREATE_TRANSACTION:
    case REQUEST_DETAIL:
    case REQUEST_MODIFY:
    case FILTERS_REQUEST:
    case FILTERS_REQUEST_HOLD:
    case UPDATE:
    case ENROLLMENT_TRANSACTION_TRANSFER_UPDATES:
    case REQUEST_ENROLLMENT_EXPORT:
    case REQUEST_ENROLLMENT_GRADE_EXPORT:
    case REQUEST_PAGANTIS_ORDER:
    case REQUEST_GET_ALL_FOR_STUDENT:
    case REQUEST_ENROLLMENT_CHANGE_METHODOLOGY:
    case REQUEST_ENROLLMENT_CHECK_METHODOLOGY:
    case REQUEST_ENROLLMENT_ACTIVATION:
    case SET_TRANSACTION_UPDATE_PRICES:
    case REQUEST:
    case REQUEST_ENROLLMENT_SERVICES:
    case REQUEST_ENROLMENT_TRANSFER_ESPERA: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_ENROLLMENT_HOLD: {
      return {
        ...state,
        loading: true,
        reload: false,
      };
    }
    case SET_TRANSACTION_UPDATE_PRICES_SUCCEEDED:
    case UPDATE_SUCCEEDED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ENROLLMENT_TRANSACTION_TRANSFER_UPDATES_SUCCEEDED:
    case DELETE_JUSTIFICANTE_TRANSFE_SUCCEEDED:
    case UPDATE_JUSTIFICANTE_TRANSFE_SUCCEEDED: {
      return {
        ...state,
        reload: true,
      };
    }
    case REQUEST_ENROLLMENT_SERVICES_SUCCEEDED:
    case REQUEST_SUCCEEDED: {
      const {
        data,
        pages,
        origin,
        bindingObject: { filters },
      } = action;
      if (origin === 'enrollments') {
        return {
          ...state,
          [origin]: data,
          filters,
          pages,
          loading: false,
        };
      }
      return {
        ...state,
        [origin]: data,
        pages,
        loading: false,
      };
    }
    case SET_TRANSACTION_UPDATE_PRICES_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cambiar el precio de las matrícula',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_ENROLLMENT_SERVICES_FAILED:
    case REQUEST_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar las matrículas',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_ENROLLMENT_ACTIVATION_SUCCEEDED: {
      utils.dialog({
        title: 'Matrículas creadas',
        message: 'Las matrículas se han creado correctamente',
      });
      return {
        ...state,
        selections: [],
        list: [],
        reload: true,
        loading: false,
      };
    }
    case REQUEST_ENROLLMENT_ACTIVATION_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido activar las matrículas',
      });
      return {
        ...state,
        reload: true,
        loading: false,
      };
    }
    case REQUEST_ENROLLMENT_HOLD_SUCCEEDED: {
      const { data: { result: { response, pages } } } = action;
      return {
        ...state,
        list: response,
        loading: false,
        reload: false,
        pages,
      };
    }
    case REQUEST_ENROLLMENT_HOLD_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar las matrículas en espera',
      });
      return {
        ...state,
        loading: false,
        reload: false,
      };
    }

    case CLEAN_FILTERS_REQUEST: {
      return {
        ...state,
        filters: {},
      };
    }
    case DOC_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DOC_REQUEST_SUCCEEDED: {
      const {
        idStudentDocument,
        url,
      } = action;
      window.open(url);
      return {
        ...state,
        viewedDocs: {
          ...state.viewedDocs,
          idStudentDocument,
          document,
        },
        loading: false,
      };
    }
    case DOC_REQUEST_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido ver el documento',
        // TODO: incluir botón "intentar de nuevo"
      });
      return {
        ...state,
        loading: false,
      };
    }
    case FILTERS_REQUEST_SUCCEEDED: {
      const { filtersData } = action;
      return {
        ...state,
        filtersData,
        loading: false,
      };
    }
    case FILTERS_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información referente a los filtros',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_GET_ALL_FOR_STUDENT_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case REQUEST_GET_ALL_FOR_STUDENT_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido cargar las matrículas para esste usuario',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_PAGANTIS_ORDER_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        pagantisOrder: data,
        loading: false,
      };
    }
    case REQUEST_PAGANTIS_ORDER_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido cargar la información desde Paga + Tarde',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_SUCCEEDED: {
      const { data: { result: { idEnrollment } } } = action;

      utils.dialog({
        type: 'info',
        title: 'Metodología cambiada',
        message: 'Esta matrícula se ha cambiado correctamente',
      });
      return {
        ...state,
        loading: false,
        reload: true,
        redirectTo: `/matriculas/editar/${idEnrollment}`,
      };
    }
    case REQUEST_ENROLLMENT_CHECK_METHODOLOGY_SUCCEEDED: {
      const { data } = action;

      const {
        academy,
        destSemesters,
        enrollment,
        enrollmentTransactions,
        origRoadMap,
      } = data.result;

      return {
        ...state,
        academy,
        destSemesters,
        enrollment,
        enrollmentTransactions,
        origRoadMap,
        loading: false,
      };
    }
    case REQUEST_DETAIL_SUCCEEDED: {
      const {
        enrollment,
        states,
        documents,
        modules,
        moduleHolds,
        payments,
        discounts,
        idAcademy,
        academies,
        collaboratingCenters,
        oldMethodology,
      } = action;
      return {
        ...state,
        enrollment,
        states,
        documents,
        modules,
        moduleHolds,
        payments,
        discounts,
        idAcademy,
        academies,
        collaboratingCenters,
        oldMethodology,
        loading: false,
      };
    }
    case REQUEST_MODIFY_FAILED:
    case REQUEST_DETAIL_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar el detalle de la matrícula',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_MODIFY_SUCCEEDED: {
      const {
        enrollment,
        enrollmentTransactions,
        roadMap,
        collaboratingCenters,
        oldMethodology,
        optionsMethodology,
      } = action;

      enrollmentTransactions.forEach((enrollmentTransaction) => {
        const modules = enrollmentTransaction.Modules.concat(enrollmentTransaction.ModuleHolds);
        modules.forEach((module) => {
          if (!module) {
            return false;
          }

          const updateModule = {
            ...module,
            newCost: module.cost,
            newAmount: module.amount,
          };

          Object.assign(module, updateModule);

          module.Units.forEach((unit) => {
            const updateUnit = {
              ...unit,
              newCost: unit.cost,
              newAmount: unit.amount,
            };

            Object.assign(unit, updateUnit);
          });

          return true;
        });
      });

      return {
        ...state,
        enrollment,
        enrollmentTransactions,
        roadMap,
        collaboratingCenters,
        oldMethodology,
        optionsMethodology,
        selectedTab: 0,
        loading: false,
      };
    }
    case SET: {
      const {
        key,
        value,
      } = action;
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          [key]: value,
        },
      };
    }
    case SET_SELECTION: {
      const {
        id,
        value,
      } = action;
      const { selections } = state;

      return {
        ...state,

        // value === true -> add the element
        // value === false -> remove the element
        selections: value ? [...selections, id] : selections.filter(s => s !== id),
      };
    }
    case REMOVE_SELECTIONS: {
      return {
        ...state,
        selections: [],
      };
    }
    case CLEAR_PAGANTIS_ORDER: {
      return {
        ...state,
        pagantisOrder: null,
      };
    }
    case REMOVE: {
      return {
        ...state,
        loading: true,
      };
    }
    case REMOVE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case PAYMENT_MARK_AS_PENDING:
    case PAYMENT_CHARGE:
    case PAYMENT_MAIL:
    case PAYMENT_MARK_AS_PAID:
    case PAYMENT_UPDATE_DEADLINE: {
      const { id } = action;
      const { payments } = state;

      return {
        ...state,
        payments: payments.map((payment) => {
          const { idEnrollmentSplitPayment: itemId } = payment;

          if (itemId === id) {
            return {
              ...payment,
              loading: true,
            };
          }

          return payment;
        }),
      };
    }
    case PAYMENT_SUCCEEDED: {
      const {
        id,
        data: updatedPayment,
      } = action;
      const { payments } = state;
      return {
        ...state,
        payments: payments.map((payment) => {
          const { idEnrollmentSplitPayment: itemId } = payment;

          if (itemId === id) return updatedPayment;
          return payment;
        }),
      };
    }
    case PAYMENT_FAILED: {
      const {
        id,
        error,
      } = action;
      const { payments } = state;

      // eslint-disable-next-line
      console.info(error);

      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message: error.message === 'Error: Error code is authentication_required'
          ? 'Error de autenticación. El banco requiere autenticación del cliente.'
          : 'No se ha podido realizar la acción',
      });

      return {
        ...state,
        payments: payments.map((payment) => {
          const { idEnrollmentSplitPayment: itemId } = payment;

          if (itemId === id) {
            return {
              ...payment,
              loading: false,
            };
          }
          return payment;
        }),
      };
    }

    case CHECK_MODULE: {
      const {
        idModule,
        value,
        semester,
      } = action;
      const {
        roadMap,
        enrollmentTransactions,
        enrollment: { idEnrollment },
      } = state;

      // find enrollmentTransactions save in BD and not paid
      const etPending = enrollmentTransactions.find(
        et => et.idEnrollmentTransaction > 0 && !et.paymentDate,
      );
      if (etPending) {
        swal('Modificación pendiente', 'Existe una modificación creada sin fecha de pago. Para un nuevo cambio se debe eliminar la modificación no pagada o realizar el pago.', 'warning');
        return {
          ...state,
          selectedTab: enrollmentTransactions.length - 1,
        };
      }
      const roadMapModule = roadMap.RoadMapModules.find(rmm => rmm.idModule === idModule);
      if (roadMapModule) {
        roadMapModule.Module.checked = value;
        roadMapModule.ModuleSemester.checked = value;
        roadMapModule.ModuleSemester.semester = semester.semester;
        roadMapModule.ModuleSemester.courseYear = semester.courseYear;
        roadMapModule.Module.semester = semester.semester;
        roadMapModule.Module.courseYear = semester.courseYear;
        roadMapModule.Module.Units.forEach((unit) => {
          unit.checked = value; // eslint-disable-line
        });
      }
      action.roadMap = roadMap;  // eslint-disable-line
      action.enrollmentTransactions = enrollmentTransactions;  // eslint-disable-line
      action.idEnrollment = idEnrollment;  // eslint-disable-line
      action.selectedTab = enrollmentTransactions.length - 1;  // eslint-disable-line
      return {
        ...state,
        selectedTab: action.selectedTab,
      };
    }
    case CHECK_UNIT: {
      const {
        idModule,
        idUnit,
        semester,
        value,
      } = action;

      const {
        roadMap,
        enrollmentTransactions,
        enrollment: { idEnrollment },
      } = state;

      // find enrollmentTransactions save in BD and not paid
      const etPending = enrollmentTransactions.find(
        et => et.idEnrollmentTransaction > 0 && !et.paymentDate,
      );
      if (etPending) {
        swal('Modificación pendiente', 'Existe una modificación creada sin fecha de pago. Para un nuevo cambio se debe eliminar la modificación no pagada o realizar el pago.', 'warning');
        return {
          ...state,
          selectedTab: enrollmentTransactions.length - 1,
        };
      }
      const roadMapModule = roadMap.RoadMapModules.find(rmm => rmm.idModule === idModule);
      if (roadMapModule) {
        const unitChecked = roadMapModule.Module.Units.find(unit => unit.idUnit === idUnit);
        if (unitChecked) {
          unitChecked.checked = value;
          if (value === true) {
            roadMapModule.Module.checked = value;
            roadMapModule.ModuleSemester.checked = value;
            roadMapModule.Module.semester = semester.semester;
            roadMapModule.Module.courseYear = semester.courseYear;
          } else {
            const checkedUnits = roadMapModule.Module.Units.find(unit => unit.checked === true);
            const isChecked = (checkedUnits);
            roadMapModule.Module.checked = isChecked;
            roadMapModule.ModuleSemester.checked = isChecked;
          }
        }
      }

      action.roadMap = roadMap;  // eslint-disable-line
      action.enrollmentTransactions = enrollmentTransactions;  // eslint-disable-line
      action.idEnrollment = idEnrollment;  // eslint-disable-line
      action.selectedTab = enrollmentTransactions.length - 1;  // eslint-disable-line
      return {
        ...state,
        selectedTab: action.selectedTab,
      };
    }
    case CHECK_UNIT_SUCCEEDED:
    case CHECK_MODULE_SUCCEEDED: {
      const { enrollmentTransactions } = action;
      const { enrollment } = state;

      // recalculate totalAmount
      enrollment.totalAmount = 0;
      enrollmentTransactions.forEach((et) => {
        enrollment.totalAmount = Math.round((
          enrollment.totalAmount + Number(et.amount)) * 100) / 100;
      });

      return {
        ...state,
        enrollment,
        enrollmentTransactions,
      };
    }
    case TAB_CHANGE: {
      const { value } = action;
      return {
        ...state,
        selectedTab: value,
      };
    }
    case MOVE_TRANSACTION_MODULE: {
      return {
        ...state,
        loading: true,
      };
    }
    case MOVE_TRANSACTION_MODULE_SUCCEEDED: {
      utils.dialog({
        type: 'reload',
        title: 'Modificando matrícula',
        message: 'El Modulo se ha cambiado correctamente',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case MOVE_TRANSACTION_MODULE_ERROR: {
      utils.dialog({
        type: 'reload',
        title: 'Modificando matrícula',
        message: 'El Modulo no se ha podido cambiar',
      });
      return {
        ...state,
        reload: true,
      };
    }
    case CREATE_TRANSACTION_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido guardar la modificación en la matrícula',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_ENROLMENT_TRANSFER_ESPERA_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido recoger las matrículas pagadas con transferencia en espera',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REMOVE_TRANSACTION_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido eliminar la modificación de la matrícula',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_ENROLLMENT_EXPORT_SUCCEEDED: {
      const {
        data,
        exportType,
      } = action;
      const encodedUri = escape(data);
      const link = document.createElement('a');
      let file = 'matriculas.csv';
      if (exportType === 'shipping') {
        file = 'matriculas_envio.csv';
      }
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodedUri}`);
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
        loading: false,
      };
    }


    case REQUEST_ENROLLMENT_GRADE_EXPORT_SUCCEEDED: {
      const { data } = action;
      const encodedUri = escape(data);
      const link = document.createElement('a');
      const file = 'matriculas_grados.csv';
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodedUri}`);
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
        loading: false,
      };
    }

    case GET_TRANSACTION_PDF_SUCCEEDED: {
      const {
        document,
        idEnrollmentTransaction,
      } = action;
      saveAs(document, `Matrícula Transacción ${idEnrollmentTransaction}`);
      return {
        ...state,
      };
    }

    case SET_MODULE_TRANSACTION: {
      const {
        idModule,
        value,
      } = action;
      const {
        enrollmentTransactions,
        selectedTab,
        enrollment,
      } = state;

      if (!isNaN(Number(value))) { // eslint-disable-line
        // select transaction
        const ct = enrollmentTransactions[selectedTab];

        // assign value to module
        const module = ct.Modules.find(mod => mod.idModule === idModule);
        module.amount = Number(value);

        // recalculate enrollment transaction amount
        ct.amount = 0;
        ct.Modules.forEach((mod) => {
          ct.amount = Math.round((
            ct.amount + Number(mod.amount)) * 100) / 100;
        });

        // recalculate enrollment general amount
        enrollment.totalAmount = 0;
        enrollmentTransactions.forEach((et) => {
          enrollment.totalAmount = Math.round((
            enrollment.totalAmount + Number(et.amount)) * 100) / 100;
        });

        return {
          ...state,
          enrollment,
          enrollmentTransactions,
        };
      }
      utils.dialog({
        type: 'warning',
        title: 'Debes introducir sólo números',
        message: 'El precio de un módulo no puede contener carácteres diferentes a números',
      });
      return {
        ...state,
      };
    }
    case SET_UNIT_TRANSACTION: {
      const {
        idModule,
        idUnit,
        value,
      } = action;
      const {
        enrollmentTransactions,
        selectedTab,
        enrollment,
      } = state;
      if (!isNaN(Number(value))) { // eslint-disable-line

        // select transaction
        const ct = enrollmentTransactions[selectedTab];

        // assign value to unit
        const module = ct.Modules.find(mod => mod.idModule === idModule);
        const unit = module.Units.find(uni => uni.idUnit === idUnit);
        unit.amount = Number(value);

        // recalculate module amount
        module.amount = 0;
        module.Units.forEach((uni) => {
          module.amount = Math.round((
            module.amount + Number(uni.amount)) * 100) / 100;
        });

        // recalculate enrollment transaction amount
        ct.amount = 0;
        ct.Modules.forEach((mod) => {
          ct.amount = Math.round((
            ct.amount + Number(mod.amount)) * 100) / 100;
        });

        // recalculate enrollment general amount
        enrollment.totalAmount = 0;
        enrollmentTransactions.forEach((et) => {
          enrollment.totalAmount = Math.round((
            enrollment.totalAmount + Number(et.amount)) * 100) / 100;
        });

        return {
          ...state,
          enrollment,
          enrollmentTransactions,
        };
      }
      utils.dialog({
        type: 'warning',
        title: 'Debes introducir sólo números',
        message: 'El precio de un módulo no puede contener carácteres diferentes a números',
      });
      return {
        ...state,
      };
    }
    case SET_MODULE_COST_TRANSACTION: {
      const {
        idModule,
        value,
      } = action;
      const {
        enrollmentTransactions,
        selectedTab,
        enrollment,
      } = state;

      // select transaction
      const ct = enrollmentTransactions[selectedTab];

      const modules = ct.Modules.concat(ct.ModuleHolds);

      // assign value to module
      const module = modules.find(mod => mod.idModule === idModule);
      module.cost = value;

      // recalculate enrollment transaction amount
      ct.amount = 0;
      modules.forEach((mod) => {
        ct.amount = Math.round((
          ct.amount + Number(mod.cost)) * 100) / 100;
      });

      // recalculate enrollment general amount
      enrollment.totalAmount = 0;
      enrollmentTransactions.forEach((et) => {
        enrollment.totalAmount = Math.round((
          enrollment.totalAmount + Number(et.amount)) * 100) / 100;
      });

      ct.Discounts.forEach((discount) => {
        ct.amount -= discount.amount;
      });

      ct.amount = ct.amount.toFixed(2);

      return {
        ...state,
        enrollment,
        enrollmentTransactions,
      };
    }
    case SET_UNIT_COST_TRANSACTION: {
      const {
        idModule,
        idUnit,
        value,
      } = action;
      const {
        enrollmentTransactions,
        selectedTab,
        enrollment,
      } = state;
      // select transaction
      const ct = enrollmentTransactions[selectedTab];

      const modules = ct.Modules.concat(ct.ModuleHolds);
      // assign value to unit
      const module = modules.find(mod => mod.idModule === idModule);
      const unit = module.Units.find(uni => uni.idUnit === idUnit);
      unit.cost = value;

      // recalculate enrollment transaction amount
      module.cost = 0;
      module.Units.forEach((uni) => {
        module.cost = Math.round((
          module.cost + Number(uni.cost)) * 100) / 100;
      });

      ct.amount = 0;
      modules.forEach((mod) => {
        ct.amount = Math.round((
          ct.amount + Number(mod.cost)) * 100) / 100;
      });

      ct.Discounts.forEach((discount) => {
        ct.amount -= discount.amount;
      });

      enrollment.totalAmount = 0;
      enrollmentTransactions.forEach((et) => {
        enrollment.totalAmount = Math.round((
          enrollment.totalAmount + Number(et.amount)) * 100) / 100;
      });

      ct.amount = ct.amount.toFixed(2);

      return {
        ...state,
        enrollment,
        enrollmentTransactions,
      };
    }
    case SET_DISCOUNT_COST_TRANSACTION: {
      const {
        newDiscount,
      } = action;
      const {
        enrollmentTransactions,
        selectedTab,
        enrollment,
      } = state;
      const currentTransaction = enrollmentTransactions[selectedTab];
      const modules = currentTransaction.Modules.concat(currentTransaction.ModuleHolds);

      currentTransaction.amount = 0;
      modules.forEach((mod) => {
        currentTransaction.amount += Number(mod.cost);
      });

      currentTransaction.Discounts.forEach((discount) => {
        if (newDiscount.idEnrollmentTransactionDiscount
          === discount.idEnrollmentTransactionDiscount) {
          // eslint-disable-next-line no-param-reassign
          discount.amount = newDiscount.amount;
        }
        currentTransaction.amount -= discount.amount;
      });

      enrollment.totalAmount = 0;
      enrollmentTransactions.forEach((et) => {
        enrollment.totalAmount = Math.round((
          enrollment.totalAmount + Number(et.amount)) * 100) / 100;
      });

      currentTransaction.amount = currentTransaction.amount.toFixed(2);

      return {
        ...state,
        enrollment,
        enrollmentTransactions,
      };
    }
    case SET_TRANSACTION_PENALTY: {
      const {
        enrollment,
        enrollmentTransactions,
        selectedTab,
      } = state;
      const { value } = action;

      // select transaction
      const ct = enrollmentTransactions[selectedTab];

      // setValue
      ct.penaltyPercentage = value;

      // first recalculate enrollment transaction amount
      ct.amount = 0;
      ct.Modules.forEach((mod) => {
        ct.amount = Math.round((
          ct.amount + Number(mod.amount)) * 100) / 100;
      });

      // apply percentage
      if (value !== 0) {
        const factor = (value / 100);
        ct.amount = Math.round((ct.amount * factor) * 100) / 100;
      }

      // recalculate enrollment general amount
      enrollment.totalAmount = 0;
      enrollmentTransactions.forEach((et) => {
        enrollment.totalAmount = Math.round((
          enrollment.totalAmount + Number(et.amount)) * 100) / 100;
      });

      return {
        ...state,
        enrollment,
        enrollmentTransactions,
      };
    }
    case DOC_DOWNLOAD_REQUEST: {
      return {
        ...state,
      };
    }
    case REQUEST_ENROLMENT_TRANSFER_ESPERA_SUCCEEDED: {
      const { data: transfers, pages } = action;
      return {
        ...state,
        transfers,
        pages,
        loading: false,
      };
    }
    case DOC_DOWNLOAD_REQUEST_SUCCEEDED: {
      const { data } = action;
      const link = document.createElement('a');
      const arr = new Uint8Array(data.Body.data);
      link.href = window.URL.createObjectURL(new Blob([arr], { type: data.ContentType }));
      const fileName = data.filename;
      link.download = fileName;
      link.click();
      return {
        ...state,
      };
    }
    case DOC_DOWNLOAD_REQUEST_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido descargar el documento',
      });
      return {
        ...state,
      };
    }
    case REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cambiar la metodología de la matricula',
      });
      return {
        ...state,
        reload: true,
      };
    }
    case REQUEST_ENROLLMENT_CHECK_METHODOLOGY_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se puede cambiar la metodología de la matricula: Compruebe que no está dada de baja y la academia a donde va a hacerse el cambio',
      });
      return {
        ...state,
        reload: true,
      };
    }
    case DELETE_JUSTIFICANTE_TRANSFE_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Eliminando justificante transferencia',
        message: 'No se ha podido eliminar',
      });
      return {
        ...state,
        reload: false,
      };
    }
    case UPDATE_JUSTIFICANTE_TRANSFE_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Modificando justificante transferencia',
        message: 'No se ha podido modificar',
      });
      return {
        ...state,
        reload: false,
      };
    }
    default:
      return state;
  }
};

export default enrollmentsReducer;
