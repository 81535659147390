import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faPencil from '@fortawesome/fontawesome-free-solid/faPencilAlt';
import faTrash from '@fortawesome/fontawesome-free-solid/faTrashAlt';
import faLock from '@fortawesome/fontawesome-free-solid/faLock';
import faLockOpen from '@fortawesome/fontawesome-free-solid/faLockOpen';
import faEye from '@fortawesome/fontawesome-free-solid/faEye';
import faTshirt from '@fortawesome/fontawesome-free-solid/faTshirt';

import {
  Button, Select, TableCell, TableRow,
} from '@material-ui/core';
import UnitItem from './UnitItem';

class ModuleItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedIdModuleSemester: -1,
    };
  }

  showUF = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  hide = ({ idRoadMapModule }) => {
    const { onClickDisabled } = this.props;
    const isActive = 0;
    onClickDisabled({
      idRoadMapModule,
      isActive,
    });
  };

  show = ({ idRoadMapModule }) => {
    const { onClickDisabled } = this.props;
    const isActive = 1;
    onClickDisabled({
      idRoadMapModule,
      isActive,
    });
  };

  changeObligatorio = ({ idRoadMapModule, isObligatorio }) => {
    const { onObligatorioClicked } = this.props;
    onObligatorioClicked({
      idRoadMapModule,
      isObligatorio,
    });
  };

  changeSemester = ({
    idRoadMapModule,
    idModuleSemester,
  }) => {
    const { updateSemester } = this.props;
    this.setState({ selectedIdModuleSemester: idModuleSemester });
    updateSemester({
      idRoadMapModule,
      idModuleSemester,
    });
  };

  render() {
    const {
      module,
      onClickUnit,
      onClickModule,
      semesters,
      idRoadMap,
      onClickDelete,
    } = this.props;

    const semesterList = semesters.map(semester => (
      <option key={semester.idModuleSemester} value={semester.idModuleSemester}>
        {semester.stage} ({semester.hoursLimit}h)
      </option>
    ));

    const {
      isOpen,
      selectedIdModuleSemester,
    } = this.state;

    const defaultIdModuleSemester = selectedIdModuleSemester !== -1
      ? selectedIdModuleSemester : (module.idModuleSemester || semesterList[0].idModuleSemester);

    let unitList;
    let unitHeader;
    let buttonExpand;
    if (module.units.length > 0) {
      if (isOpen) {
        unitHeader = (
          <TableRow>
            <TableCell className="cell-unit"><strong>CÓDIGO UF</strong></TableCell>
            <TableCell className="cell-unit"><strong>NOMBRE UF</strong></TableCell>
            <TableCell className="cell-unit" />
            <TableCell className="cell-unit" />
            <TableCell className="cell-unit" />
          </TableRow>
        );
        unitList = module.units.map(item => (
          <UnitItem
            key={item.code}
            unit={item}
            onClick={onClickUnit}
          />
        ));
        buttonExpand = (
          <Button className="button-expansive-ufs" onClick={() => this.showUF()}>
            OCULTAR UFS
            <FontAwesomeIcon icon={faAngleUp} className="icon-angle" />
          </Button>
        );
      } else {
        buttonExpand = (
          <Button className="button-expansive-ufs" onClick={() => this.showUF()}>
            VER UFS
            <FontAwesomeIcon icon={faAngleDown} className="icon-angle" />
          </Button>
        );
      }
    }

    const moduleItem = (
      <React.Fragment>
        <TableRow className={!module.active ? 'cell-unit-disabled' : ''}>
          <TableCell
            className="cell-content cell-content-code"
            align="left"
          >
            {module.extra ? (
              <FontAwesomeIcon icon={faTshirt} className="icon-pencil" />
            ) : ''}
            {module.code}
          </TableCell>
          <TableCell className="cell-content">
            {module.name}
          </TableCell>
          <TableCell className="cell-content">
            <div>
              <Select
                native
                label="Módulos"
                className="selectSemester"
                style={{ width: '100%;' }}
                value={defaultIdModuleSemester}
                defaultValue={defaultIdModuleSemester}
                onChange={e => this.changeSemester(
                  {
                    idRoadMapModule: module.idRoadMapModule,
                    idModuleSemester: e.target.value,
                  },
                )}
              >
                {semesterList}
              </Select>
            </div>
          </TableCell>
          <TableCell className="cell-content">
            {buttonExpand}
          </TableCell>
          <TableCell className="cell-content cell-content-actions">
            {
              module.obligatorio === 1 ? (
                <Button onClick={
                  () => this.changeObligatorio(
                    {
                      idRoadMapModule: module.idRoadMapModule,
                      isObligatorio: false,
                    },
                  )}
                >
                  <FontAwesomeIcon icon={faLock} className="icon-pencil" />
                </Button>
              ) : (
                <Button onClick={
                  () => this.changeObligatorio(
                    {
                      idRoadMapModule: module.idRoadMapModule,
                      isObligatorio: true,
                    },
                  )}
                >
                  <FontAwesomeIcon icon={faLockOpen} className="icon-pencil" />
                </Button>
              )
            }
            {module.active ? (
              <Button onClick={() => this.hide({ idRoadMapModule: module.idRoadMapModule })}>
                <FontAwesomeIcon icon={faEye} className="icon-pencil" />
              </Button>
            ) : (
              <Button onClick={() => this.show({ idRoadMapModule: module.idRoadMapModule })}>
                <FontAwesomeIcon icon={faEye} className="icon-pencil" />
              </Button>
            )}
            <Button onClick={() => onClickModule({
              idModule: module.idModule,
              edit: true,
            })}
            >
              <FontAwesomeIcon icon={faPencil} className="icon-pencil" />
            </Button>
            <Button onClick={() => onClickDelete({
              idRoadMap,
              idModule: module.idModule,
            })}
            >
              <FontAwesomeIcon icon={faTrash} className="icon-trash" />
            </Button>
          </TableCell>
        </TableRow>
        {unitHeader}
        {unitList}
      </React.Fragment>
    );

    return moduleItem;
  }
}

ModuleItem.propTypes = {
  module: PropTypes.object.isRequired,
  onClickUnit: PropTypes.func.isRequired,
  onClickModule: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  semesters: PropTypes.array.isRequired,
  idRoadMap: PropTypes.number.isRequired,
  updateSemester: PropTypes.func.isRequired,
  onClickDisabled: PropTypes.func.isRequired,
  onObligatorioClicked: PropTypes.func.isRequired,
};

export default ModuleItem;
