import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faBars from '@fortawesome/fontawesome-free-solid/faBars';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import { Drawer } from '@material-ui/core';

import { logout } from '$redux/login/actions';

import Header from '$components/layout/Header';
import Main from '$components/layout/Main';
import Footer from '$components/layout/Footer';
import Menu from '$components/Menu';
import { Logo, MenuText, MenuButton } from '$components/layout/Header.styles';

import routes from '$routes';

import { version } from '$root/package.json';

import { Wrapper, MenuDrawerWrapper } from './App.styles';

// import { request as requestStudent } from '$redux/student/actions';

class Private extends React.Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };
  }

  componentDidMount() {
    // const { dispatch } = this.props;
    // dispatch(requestStudent());
  }

  onClickLogout = () => {
    const { dispatch } = this.props;
    dispatch(logout());
  }

  toggleMenu = () => {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }

  render() {
    // user is logged from here!
    const { login: { user: { name } } } = this.props;
    const { menuOpen } = this.state;

    return (
      <Wrapper>
        <Header>
          <Logo>
            <img src="/img/linkia-logo-horizontal.png" alt="LinkiaFP" />
            <MenuText size="small" color="#fbeb73">v{version}</MenuText>
          </Logo>
          <MenuText>Hola, {name}</MenuText>
          <MenuButton onClick={this.onClickLogout}>
            Salir
          </MenuButton>
          <MenuButton isIcon onClick={this.toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </MenuButton>
        </Header>
        <Main>
          <div className="columns">
            <div className="column is-2-fifth-widescreen is-2-fifth-desktop is-hidden-touch">
              <nav>
                <Menu />
              </nav>
            </div>
            <div className="column is-10-widescreen is-10-desktop is-full">
              {routes.map(r => <Route key={r.path} exact {...r} />)}
            </div>
          </div>
        </Main>
        <Footer>
          <div />
        </Footer>
        <Drawer anchor="right" open={menuOpen}>
          <MenuDrawerWrapper>
            <div className="actions-container">
              <button type="button" className="close-button" onClick={this.toggleMenu}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <Menu onClickItems={this.toggleMenu} />
          </MenuDrawerWrapper>
        </Drawer>
      </Wrapper>
    );
  }
}

Private.propTypes = {
  dispatch: PropTypes.func.isRequired,
  login: PropTypes.object.isRequired,
};

export default withRouter(connect(
  state => ({
    login: state.login,
  }),
)(Private));
