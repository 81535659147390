export const REQUEST = 'ENROLLMENT_LIST_REQUEST';
export const REQUEST_SUCCEEDED = 'ENROLLMENT_LIST_REQUEST_SUCCEEDED';
export const REQUEST_FAILED = 'ENROLLMENT_LIST_REQUEST_FAILED';

export const DOC_REQUEST = 'ENROLLMENT_DOC_REQUEST';
export const DOC_REQUEST_SUCCEEDED = 'ENROLLMENT_DOC_REQUEST_SUCCEEDED';
export const DOC_REQUEST_FAILED = 'ENROLLMENT_DOC_REQUEST_FAILED';

export const DOC_DOWNLOAD_REQUEST = 'ENROLLMENT_DOC_DOWNLOAD_REQUEST';
export const DOC_DOWNLOAD_REQUEST_SUCCEEDED = 'ENROLLMENT_DOC_DOWNLOAD_REQUEST_SUCCEEDED';
export const DOC_DOWNLOAD_REQUEST_FAILED = 'ENROLLMENT_DOC_DOWNLOAD_REQUEST_FAILED';

export const FILTERS_REQUEST = 'ENROLLMENT_FILTERS_REQUEST';
export const FILTERS_REQUEST_SUCCEEDED = 'ENROLLMENT_FILTERS_REQUEST_SUCCEEDED';
export const FILTERS_REQUEST_FAILED = 'ENROLLMENT_FILTERS_REQUEST_FAILED';

export const FILTERS_REQUEST_HOLD = 'ENROLLMENT_FILTERS_REQUEST_HOLD';

export const REQUEST_DETAIL = 'ENROLLMENT_DETAIL_REQUEST';
export const REQUEST_DETAIL_SUCCEEDED = 'ENROLLMENT_DETAIL_REQUEST_SUCCEEDED';
export const REQUEST_DETAIL_FAILED = 'ENROLLMENT_DETAIL_REQUEST_FAILED';

export const REQUEST_MODIFY = 'ENROLLMENT_MODIFY_REQUEST';
export const REQUEST_MODIFY_SUCCEEDED = 'ENROLLMENT_MODIFY_REQUEST_SUCCEEDED';
export const REQUEST_MODIFY_FAILED = 'ENROLLMENT_MODIFY_REQUEST_FAILED';

export const UPDATE = 'ENROLLMENT_UPDATE';
export const UPDATE_SUCCEEDED = 'ENROLLMENT_UPDATE_SUCCEEDED';

export const UPDATE_DOC = 'DOCUMENT_UPDATE';
export const UPDATE_DOC_SUCCEEDED = 'DOCUMENT_UPDATE_SUCCEEDED';

export const ENROLLMENT_TRANSACTION_TRANSFER_UPDATES = 'ENROLLMENT_TRANSACTION_TRANSFER_UPDATES';
export const ENROLLMENT_TRANSACTION_TRANSFER_UPDATES_SUCCEEDED = 'ENROLLMENT_TRANSACTION_TRANSFER_UPDATES_SUCCEEDED';
export const SET = 'ENROLLMENT_SET';

export const SET_SELECTION = 'ENROLLMENT_SET_SELECTION';
export const REMOVE_SELECTIONS = 'ENROLLMENT_REMOVE_SELECTIONS';

export const REMOVE = 'ENROLLMENT_REMOVE';
export const REMOVE_SUCCEEDED = 'ENROLLMENT_REMOVE_SUCCEEDED';

export const PAYMENT_MARK_AS_PENDING = 'ENROLLMENT_PAYMENT_MARK_AS_PENDING';
export const PAYMENT_CHARGE = 'ENROLLMENT_PAYMENT_CHARGE';
export const PAYMENT_MAIL = 'ENROLLMENT_PAYMENT_MAIL';
export const PAYMENT_MARK_AS_PAID = 'ENROLLMENT_PAYMENT_MARK_AS_PAID';
export const PAYMENT_UPDATE_DEADLINE = 'ENROLLMENT_PAYMENT_UPDATE_DEADLINE';
export const PAYMENT_SUCCEEDED = 'ENROLLMENT_PAYMENT_SUCCEEDED';
export const PAYMENT_FAILED = 'ENROLLMENT_PAYMENT_FAILED';

export const CHECK_MODULE = 'ENROLLMENT_CHECK_MODULE';
export const CHECK_UNIT = 'ENROLLMENT_CHECK_UNIT';
export const CHECK_UNIT_SUCCEEDED = 'ENROLLMENT_CHECK_UNIT_SUCCEEDED';
export const CHECK_MODULE_SUCCEEDED = 'ENROLLMENT_CHECK_MODULE_SUCCEEDED';

export const MOVE_TRANSACTION_MODULE = 'ENROLLMENT_TRANSACTION_MOVE_MODULE';
export const MOVE_TRANSACTION_MODULE_SUCCEEDED = 'ENROLLMENT_TRANSACTION_MOVE_SUCCEEDED';
export const MOVE_TRANSACTION_MODULE_ERROR = 'ENROLLMENT_TRANSACTION_MOVE_MODULE_ERROR';

export const TAB_CHANGE = 'ENROLLMENT_TRANSACTION_TAB_CHANGE';
export const CREATE_TRANSACTION = 'ENROLLMENT_TRANSACTION_CREATE';
export const REMOVE_TRANSACTION = 'ENROLLMENT_TRANSACTION_REMOVE';
export const CREATE_TRANSACTION_FAILED = 'ENROLLMENT_TRANSACTION_CREATE_FAILED';
export const REMOVE_TRANSACTION_FAILED = 'ENROLLMENT_TRANSACTION_REMOVE_FAILED';

export const CLEAN_FILTERS_REQUEST = 'ENROLLMENT_CLEAN_FILTERS_REQUEST';
export const REQUEST_ENROLLMENT_EXPORT = 'ENROLLMENT_EXPORT_REQUEST';
export const REQUEST_ENROLLMENT_EXPORT_SUCCEEDED = 'ENROLLMENT_EXPORT_REQUEST_SUCCEEDED';

export const REQUEST_ENROLLMENT_GRADE_EXPORT = 'ENROLLMENT_EXPORT_GRADE_REQUEST';
export const REQUEST_ENROLLMENT_GRADE_EXPORT_SUCCEEDED = 'ENROLLMENT_EXPORT_GRADE_REQUEST_SUCCEEDED';

export const GET_TRANSACTION_PDF = 'ENROLLMENT_GET_TRANSACTION_PDF';
export const GET_TRANSACTION_PDF_SUCCEEDED = 'ENROLLMENT_GET_TRANSACTION_PDF_SUCCEEDED';

export const SET_MODULE_TRANSACTION = 'ENROLLMENT_SET_MODULE_TRANSACTION';
export const SET_UNIT_TRANSACTION = 'ENROLLMENT_SET_UNIT_TRANSACTION';

export const SET_MODULE_COST_TRANSACTION = 'ENROLLMENT_SET_MODULE_COST_TRANSACTION';
export const SET_UNIT_COST_TRANSACTION = 'ENROLLMENT_SET_UNIT_COST_TRANSACTION';
export const SET_DISCOUNT_COST_TRANSACTION = 'ENROLLMENT_SET_DISCOUNT_COST_TRANSACTION';

export const SET_TRANSACTION_PENALTY = 'ENROLLMENT_SET_PENALTY';

export const REQUEST_PAGANTIS_ORDER = 'PAGANTIS_GET_ORDER';
export const REQUEST_PAGANTIS_ORDER_SUCCEEDED = 'PAGANTIS_GET_ORDER_SUCCEEDED';
export const REQUEST_PAGANTIS_ORDER_FAILED = 'PAGANTIS_GET_ORDER_FAILED';

export const CLEAR_PAGANTIS_ORDER = 'PAGANTIS_CLEAR_ORDER';

export const REQUEST_GET_ALL_FOR_STUDENT = 'REQUEST_GET_ALL_FOR_STUDENT';
export const REQUEST_GET_ALL_FOR_STUDENT_SUCCEEDED = 'REQUEST_GET_ALL_FOR_STUDENT_SUCCEEDED';
export const REQUEST_GET_ALL_FOR_STUDENT_FAILED = 'REQUEST_GET_ALL_FOR_STUDENT_FAILED';

export const REQUEST_ENROLLMENT_CHANGE_METHODOLOGY = 'REQUEST_ENROLLMENT_CHANGE_METHODOLOGY';
export const REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_SUCCEEDED = 'REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_SUCCEEDED';
export const REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_FAILED = 'REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_FAILED';

export const REQUEST_ENROLLMENT_CHECK_METHODOLOGY = 'REQUEST_ENROLLMENT_CHECK_METHODOLOGY';
export const REQUEST_ENROLLMENT_CHECK_METHODOLOGY_SUCCEEDED = 'REQUEST_ENROLLMENT_CHECK_METHODOLOGY_SUCCEEDED';
export const REQUEST_ENROLLMENT_CHECK_METHODOLOGY_FAILED = 'REQUEST_ENROLLMENT_CHECK_METHODOLOGY_FAILED';

export const REQUEST_ENROLLMENT_HOLD = 'REQUEST_ENROLLMENT_HOLD';
export const REQUEST_ENROLLMENT_HOLD_SUCCEEDED = 'REQUEST_ENROLLMENT_HOLD_SUCCEEDED';
export const REQUEST_ENROLLMENT_HOLD_FAILED = 'REQUEST_ENROLLMENT_HOLD_FAILED';

export const REQUEST_ENROLLMENT_ACTIVATION = 'REQUEST_ENROLLMENT_ACTIVATION';
export const REQUEST_ENROLLMENT_ACTIVATION_SUCCEEDED = 'REQUEST_ENROLLMENT_ACTIVATION_SUCCEEDED';
export const REQUEST_ENROLLMENT_ACTIVATION_FAILED = 'REQUEST_ENROLLMENT_ACTIVATION_FAILED';

export const SET_TRANSACTION_UPDATE_PRICES = 'ENROLLMENT_SET_TRANSACTION_UPDATE_PRICES';
export const SET_TRANSACTION_UPDATE_PRICES_SUCCEEDED = 'ENROLLMENT_SET_TRANSACTION_UPDATE_PRICES_SUCCEEDED';
export const SET_TRANSACTION_UPDATE_PRICES_FAILED = 'ENROLLMENT_SET_TRANSACTION_UPDATE_PRICES_FAILED';

export const REQUEST_ENROLLMENT_SERVICES = 'ENROLLMENT_SERVICES';
export const REQUEST_ENROLLMENT_SERVICES_SUCCEEDED = 'ENROLLMENT_SERVICES_SUCCEEDED';
export const REQUEST_ENROLLMENT_SERVICES_FAILED = 'ENROLLMENT_SERVICES_FAILED';
export const DELETE_JUSTIFICANTE_TRANSFE = 'DELETE_JUSTIFICANTE_TRANSFE';
export const DELETE_JUSTIFICANTE_TRANSFE_SUCCEEDED = 'DELETE_JUSTIFICANTE_TRANSFE_SUCCEEDED';
export const DELETE_JUSTIFICANTE_TRANSFE_FAILED = 'DELETE_JUSTIFICANTE_TRANSFE_FAILED';
export const UPDATE_JUSTIFICANTE_TRANSFE = 'UPDATE_JUSTIFICANTE_TRANSFE';
export const UPDATE_JUSTIFICANTE_TRANSFE_SUCCEEDED = 'UPDATE_JUSTIFICANTE_TRANSFE_SUCCEEDED';
export const UPDATE_JUSTIFICANTE_TRANSFE_FAILED = 'UPDATE_JUSTIFICANTE_TRANSFE_FAILED';

export const REQUEST_ENROLMENT_TRANSFER_ESPERA = 'ENROLMENT_TRANSFER_ESPERA';
export const REQUEST_ENROLMENT_TRANSFER_ESPERA_SUCCEEDED = 'ENROLMENT_TRANSFER_ESPERA_SUCCEEDED';
export const REQUEST_ENROLMENT_TRANSFER_ESPERA_FAILED = 'ENROLMENT_TRANSFER_ESPERA_FAILED';
