import styled from 'styled-components';

export const Wrapper = styled.div`
  .column-code {
    width: 11%;
  }

  .column-semesters {
    width: 30%;
  }

  .selectSemester {
    width: 100%;
  }

  .column-button {
    width: 15%;
  }

  .cell-module {
    padding: 8px;
  }

  .cell-unit {
    padding: 8px 8px 8px 16px;
  }

  .cell-unit-disabled {
    background: #ffffff;
    opacity: 0.3;
  }

  .cell-unit-text-disabled {
    color: #cdcdcd;
  }

  .table-modules {
    table-layout: fixed;

    div[class^='MuiTableCell-body'], div[class*=' MuiTableCell-body'] {
      padding: 4px 0 4px 10px !important;
    }

  }

  .cell-right {
    text-align: right !important;
  }

  .cell-center {
    text-align: center !important;
  }

  .button-expansive-ufs {
    color: #6896c8;
  }

  .icon-angle {
    margin-left: 4px;
  }

  @media (max-width: 1200px) {
    .cell-content {
      padding: 4px 0 4px 10px !important;
    }
  }

  .cell-content-ufs {
      padding: 4px 0 4px 4px !important;
      width: 120px !important;
  }

  .cell-content {
    padding: 8px;
  }

  .cell-content-code {
    word-wrap: break-word;
    svg {
      margin-right: 12px;
      font-size: 1rem;
    }
  }

  .cell-content-actions {
    padding-right: 0px !important;
  }

`;
