import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Title from 'ui/Title';
import theme from 'ui/theme';

import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';


class Enrollments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  render() {
    const {
      selections,
    } = this.props;
    const { filters } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>
          Matrículas
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid
          filters={filters}
          selections={selections}
          setSelection={this.setSelection}
        />
      </Wrapper>
    );
  }
}

Enrollments.propTypes = {
  selections: PropTypes.array.isRequired,
};

export default connect(
  () => ({}),
)(Enrollments);
