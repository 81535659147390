import styled from 'styled-components';

export const Wrapper = styled.header`
  background-color: #222;
  color: #fff;
  margin-bottom: 50px;
`;

export const HeaderItems = styled.div`
  display: flex;
  height: 60px;
  padding: 0 30px;
  align-items: center;

  img {
    max-height: 32px;
  }
`;

export const Logo = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  img { transform: translateY(-3px); }
`;

export const MenuText = styled.span`
  margin-left: 30px;
  font-weight: 300;
  font-size: ${({ size }) => (size === 'small' ? '14px' : '18px')};
  color: ${({ color }) => color || 'unset'};
`;

export const MenuButton = styled.button`
  margin-left: 30px;

  transform: scaleX(${({ isIcon }) => (isIcon ? 1.3 : 1)});
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  background: #333;
  border: none;

  :hover {
    background: #494949;
  }
`;
