import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  CHECK_MODULE,
  CHECK_UNIT,
  CREATE_TRANSACTION,
  DELETE_JUSTIFICANTE_TRANSFE,
  DOC_DOWNLOAD_REQUEST,
  DOC_REQUEST,
  ENROLLMENT_TRANSACTION_TRANSFER_UPDATES,
  FILTERS_REQUEST,
  FILTERS_REQUEST_HOLD,
  GET_TRANSACTION_PDF,
  MOVE_TRANSACTION_MODULE,
  PAYMENT_CHARGE,
  PAYMENT_MAIL,
  PAYMENT_MARK_AS_PAID,
  PAYMENT_MARK_AS_PENDING,
  PAYMENT_UPDATE_DEADLINE,
  REMOVE,
  REMOVE_TRANSACTION,
  REQUEST,
  REQUEST_DETAIL,
  REQUEST_ENROLLMENT_ACTIVATION,
  REQUEST_ENROLLMENT_CHANGE_METHODOLOGY,
  REQUEST_ENROLLMENT_CHECK_METHODOLOGY,
  REQUEST_ENROLLMENT_EXPORT,
  REQUEST_ENROLLMENT_GRADE_EXPORT,
  REQUEST_ENROLLMENT_HOLD,
  REQUEST_ENROLLMENT_SERVICES,
  REQUEST_GET_ALL_FOR_STUDENT,
  REQUEST_MODIFY,
  REQUEST_PAGANTIS_ORDER,
  SET_TRANSACTION_UPDATE_PRICES,
  UPDATE,
  UPDATE_DOC,
  UPDATE_JUSTIFICANTE_TRANSFE,
  REQUEST_ENROLMENT_TRANSFER_ESPERA,
} from './action-types';

import {
  checkModuleSucceeded,
  checkUnitSucceeded,
  createTransactionFailed,
  deleteEnrollmentJustificanteTransfeFailed,
  deleteEnrollmentJustificanteTransfeSucceeded,
  enrollmentTransactionTransferUpdatesSucceeded,
  filtersRequestFailed,
  filtersRequestSucceeded,
  getTransactionPdfSucceeded,
  moveModuleTransactionError,
  moveModuleTransactionSucceeded,
  paymentFailed,
  paymentSucceeded,
  removeEnrollmentSucceeded,
  removeTransactionFailed,
  request,
  requestDetailFailed,
  requestDetailSucceeded,
  requestDocFailed,
  requestDocSucceeded,
  requestDownloadDocFailed,
  requestDownloadDocSucceeded,
  requestEnrollmentActivationFailed,
  requestEnrollmentActivationSucceded,
  requestEnrollmentChangeMethodologyFailed,
  requestEnrollmentChangeMethodologySucceeded,
  requestEnrollmentCheckMethodologyFailed,
  requestEnrollmentCheckMethodologySucceeded,
  requestEnrollmentExportGradeSucceeded,
  requestEnrollmentExportSucceeded,
  requestEnrollmentHoldFailed,
  requestEnrollmentHoldSucceded,
  requestEnrollmentModifyFailed,
  requestEnrollmentModifySucceeded,
  requestEnrollmentServicesFailed,
  requestEnrollmentServicesSucceeded,
  requestFailed,
  requestGetAllForStudentFailed,
  requestGetAllForStudentSucceded,
  requestPagantisOrderFailed,
  requestPagantisOrderSucceeded,
  requestSucceeded,
  setTransactionUpdatePricesFailed,
  setTransactionUpdatePricesSucceeded,
  updateEnrollmentJustificanteTransfeFailed,
  updateEnrollmentJustificanteTransfeSucceeded,
  updateSucceeded,
  requestEnrollmentTransferEsperaSucceeded,
  requestEnrollmentTransferEsperaFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* reqEnrollments(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
    origin,
  } = action;
  try {
    const data = yield call(api.enrollment.getAllFiltered, {
      bindingObject,
      token,
    });
    yield put(requestSucceeded(data, origin, bindingObject));
  } catch (e) {
    yield put(requestFailed());
  }
}

function* reqEnrollmentHold(action) {
  const token = yield select(tokenSelector);
  const {
    search,
    semester,
  } = action;
  const bindingObject = {
    search,
    semester,
  };
  try {
    const data = yield call(api.enrollment.hold, {
      bindingObject,
      token,
    });
    yield put(requestEnrollmentHoldSucceded(data));
  } catch (e) {
    yield put(requestEnrollmentHoldFailed());
  }
}

function* reqEnrollmentActivation(action) {
  const token = yield select(tokenSelector);
  const bindingObject = {
    semester: action.semester,
    activations: action.activations,
  };
  try {
    const data = yield call(api.enrollment.activation, {
      bindingObject,
      token,
    });
    yield put(requestEnrollmentActivationSucceded(data));
  } catch (e) {
    yield put(requestEnrollmentActivationFailed());
  }
}

function* reqDocument(action) {
  const { idStudentDocument } = action;
  const token = yield select(tokenSelector);
  try {
    const url = yield call(api.enrollment.getStudentDocumentUrl, {
      token,
      idStudentDocument,
    });
    yield put(requestDocSucceeded(idStudentDocument, url));
  } catch (e) {
    yield put(requestDocFailed());
  }
}

function* reqDownloadDocument(action) {
  const { idStudentDocument } = action;
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.enrollment.getStudentDocument, {
      token,
      idStudentDocument,
    });
    yield put(requestDownloadDocSucceeded(idStudentDocument, data));
  } catch (e) {
    yield put(requestDownloadDocFailed());
  }
}

function* reqFilters() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.enrollment.getFilters, { token });
    yield put(filtersRequestSucceeded(filtersData));
  } catch (e) {
    yield put(filtersRequestFailed());
  }
}

function* reqFilterHolds() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.enrollment.getFilterHolds, { token });
    yield put(filtersRequestSucceeded(filtersData));
  } catch (e) {
    yield put(filtersRequestFailed());
  }
}

function* reqEnrollmentsDetail(action) {
  const token = yield select(tokenSelector);
  const {
    idEnrollment,
  } = action;
  try {
    const data = yield call(api.enrollment.getEnrollmentDetail, {
      idEnrollment,
      token,
    });
    data.collaboratingCenters = yield call(api.collaboratingCenter.getAll, { token });
    yield put(requestDetailSucceeded(data));
  } catch (e) {
    yield put(requestDetailFailed());
  }
}

function* reqEnrollmentsModify(action) {
  const token = yield select(tokenSelector);
  const {
    idEnrollment,
  } = action;
  try {
    const data = yield call(api.enrollment.getEnrollmentToModify, {
      idEnrollment,
      token,
    });
    yield put(requestEnrollmentModifySucceeded(data));
  } catch (e) {
    yield put(requestEnrollmentModifyFailed());
  }
}

function* updateEnrollment(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const idEnrollment = yield call(api.enrollment.update, {
      ...data,
      token,
    });
    const dataOK = yield call(api.enrollment.getEnrollmentDetail, {
      idEnrollment,
      token,
    });
    dataOK.collaboratingCenters = yield call(api.collaboratingCenter.getAll, { token });
    yield put(requestDetailSucceeded(dataOK));
    yield put(updateSucceeded(true));
  } catch (e) {
    yield put(requestDetailFailed());
  }
}

function* enrollmentTransactionTranserUpdates(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    yield call(api.enrollmentTransactionTransfer.updates, {
      token,
      data,
    });
    yield put(enrollmentTransactionTransferUpdatesSucceeded(true));
  } catch (e) {
    yield put(requestDetailFailed());
  }
}

function* updateDocument(action) {
  const token = yield select(tokenSelector);
  const {
    idStudentDocument,
    idStudent,
    idEnrollment,
    checked,
  } = action;
  const docInfo = {
    idStudentDocument,
    idStudent,
    idEnrollment,
    checked,
  };
  try {
    const dataOK = yield call(api.enrollment.checkEnrollmentDocument, {
      token,
      docInfo,
    });
    yield put(requestDetailSucceeded(dataOK));
    yield put(updateSucceeded(true));
  } catch (e) {
    yield put(requestDetailFailed());
  }
}

function* reqRemoveEnrollment(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  const bindingObject = {
    pageSize: 10,
    page: 0,
    sorted: [],
    filters: {},
  };
  try {
    yield call(api.enrollment.deleteEnrollment, {
      idEnrollment: id,
      token,
    });
    yield put(request(bindingObject, 'enrollments'));
    yield put(removeEnrollmentSucceeded());
  } catch (error) {
    yield put(requestEnrollmentModifyFailed());
  }
}

function* reqPaymentMarkAsPending(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const updatedPayment = yield call(
      api.enrollment.updateEnrollmentPayment, {
        token,
        id,
        data: { paymentDate: null },
      },
    );
    yield put(paymentSucceeded(id, updatedPayment));
  } catch (error) {
    yield put(paymentFailed(id, error));
  }
}

function* reqPaymentCharge(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const updatedPayment = yield call(api.enrollment.chargeEnrollmentPayment, {
      token,
      id,
    });
    if (updatedPayment.error) {
      throw new Error(updatedPayment.error);
    }
    yield put(paymentSucceeded(id, updatedPayment));
  } catch (error) {
    yield put(paymentFailed(id, error));
  }
}

function* reqPaymentSendMail(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const updatedPayment = yield call(api.enrollment.sendEnrollmentPayment, {
      token,
      id,
    });
    if (updatedPayment.error) {
      throw new Error(updatedPayment.error);
    }
    yield put(paymentSucceeded(id, updatedPayment));
  } catch (error) {
    yield put(paymentFailed(id, error));
  }
}

function* reqPaymentMarkAsPaid(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const updatedPayment = yield call(
      api.enrollment.updateEnrollmentPayment, {
        token,
        id,
        data: { paymentDate: new Date() },
      },
    );
    yield put(paymentSucceeded(id, updatedPayment));
  } catch (error) {
    yield put(paymentFailed(id, error));
  }
}

function* reqPaymentUpdateDeadline(action) {
  const token = yield select(tokenSelector);
  const {
    id,
    value,
  } = action;
  try {
    const updatedPayment = yield call(
      api.enrollment.updateEnrollmentPayment, {
        token,
        id,
        data: { deadlineDate: value },
      },
    );
    yield put(paymentSucceeded(id, updatedPayment));
  } catch (error) {
    yield put(paymentFailed(id, error));
  }
}

function* reqCheckModule(action) {
  const token = yield select(tokenSelector);
  const {
    idEnrollment,
    roadMap,
    enrollmentTransactions,
    selectedTab,
    idModule,
    value,
    semester,
  } = action;

  const choices = {
    roadMap,
    enrollmentTransactions,
    selectedTab,
    idModule,
    idUnit: 0,
    value,
    semester,
  };

  try {
    if (idEnrollment) {
      const data = yield call(api.enrollment.reCalculateEnrollment, {
        token,
        idEnrollment,
        choices,
      });
      yield put(checkModuleSucceeded(data));
    }
  } catch (e) {
    console.log('reqCheckModule', e); // eslint-disable-line
    // no throw error
  }
}

function* reqCheckUnit(action) {
  const token = yield select(tokenSelector);
  const {
    idEnrollment,
    roadMap,
    enrollmentTransactions,
    selectedTab,
    idModule,
    idUnit,
    value,
    semester,
  } = action;
  const choices = {
    roadMap,
    enrollmentTransactions,
    selectedTab,
    idModule,
    idUnit,
    value,
    semester,
  };
  try {
    if (idEnrollment) {
      const data = yield call(api.enrollment.reCalculateEnrollment, {
        token,
        idEnrollment,
        choices,
      });
      yield put(checkUnitSucceeded(data));
    }
  } catch (e) {
    console.log('reqCheckUnit', e); // eslint-disable-line
    // no throw error
  }
}

function* reqMoveTransactionModule(action) {
  const token = yield select(tokenSelector);
  const {
    idEnrollment,
    idModule,
    semester,
    courseYear,
  } = action;

  const data = {
    idModule,
    moduleSemesterSemester: semester,
    moduleSemesterCourseYear: courseYear,
  };

  try {
    const response = yield call(api.enrollmentTransaction.moveEnrollmentTransactionModule, {
      token,
      idEnrollmentTransaction: idEnrollment,
      data,
    });
    yield put(moveModuleTransactionSucceeded(response));
  } catch (e) {
    yield put(moveModuleTransactionError(e.message));
  }
}

function* reqCreateTransaction(action) {
  const token = yield select(tokenSelector);
  const { enrollmentTransaction } = action.data;
  try {
    const enrollmentTransactionObject = { enrollmentTransaction };
    const data = yield call(
      api.enrollmentTransaction.createEnrollmentTransaction, {
        token,
        enrollmentTransactionObject,
      },
    );
    yield put(requestEnrollmentModifySucceeded(data));
  } catch (error) {
    yield put(createTransactionFailed());
  }
}

function* reqRemoveTransaction(action) {
  const token = yield select(tokenSelector);
  const { idEnrollmentTransaction } = action;
  try {
    const data = yield call(
      api.enrollmentTransaction.delete, {
        token,
        idEnrollmentTransaction,
      },
    );
    yield put(requestEnrollmentModifySucceeded(data));
  } catch (error) {
    yield put(removeTransactionFailed());
  }
}

function* reqEnrollmentExport(action) {
  const token = yield select(tokenSelector);
  const { bindingObject } = action;
  const { type } = bindingObject;
  try {
    const data = yield call(api.enrollment.getFilteredExport, {
      bindingObject,
      token,
    });
    yield put(requestEnrollmentExportSucceeded(data, type));
  } catch (e) {
    console.log('reqEnrollmentExport', e); // eslint-disable-line
    // no throw error
  }
}

function* reqEnrollmentGradeExport(action) {
  const token = yield select(tokenSelector);
  const { bindingObject } = action;
  const { type } = bindingObject;
  try {
    const data = yield call(api.student.getStudentExportGrades, {
      bindingObject,
      token,
    });
    yield put(requestEnrollmentExportGradeSucceeded(data, type));
  } catch (e) {
    console.log('reqEnrollmentGradeExport', e); // eslint-disable-line
    // no throw error
  }
}

function* reqGetTransactionPdf(action) {
  const token = yield select(tokenSelector);
  const { idEnrollmentTransaction } = action;
  try {
    const res = yield call(api.enrollmentTransaction.getTransactionPdf, {
      token,
      idEnrollmentTransaction,
    });
    const document = res.data;
    yield put(getTransactionPdfSucceeded(document, idEnrollmentTransaction));
  } catch (e) {
    yield put(requestDocFailed());
  }
}

function* reqGetPagantisOrder(action) {
  const token = yield select(tokenSelector);
  const { orderId } = action;
  try {
    const data = yield call(api.order.getPagantisOrder, {
      orderId,
      token,
    });
    yield put(requestPagantisOrderSucceeded(data));
  } catch (error) {
    yield put(requestPagantisOrderFailed());
  }
}

function* reqGetAllForStudents(action) {
  const token = yield select(tokenSelector);
  const { idStudent } = action;
  try {
    const data = yield call(api.enrollment.getAllForStudent, {
      idStudent,
      token,
    });
    yield put(requestGetAllForStudentSucceded(data));
  } catch (e) {
    yield put(requestGetAllForStudentFailed());
  }
}

function* reqEnrollmentChangeMethodology(action) {
  const token = yield select(tokenSelector);
  const {
    idEnrollment,
    idTrainingPlan,
    idRoadMap,
    idAcademy,
    roadMap,
  } = action;

  try {
    const data = yield call(api.enrollment.changeEnrollmentMethodologyConfirm, {
      idEnrollment,
      idTrainingPlan,
      idRoadMap,
      idAcademy,
      roadMap,
      token,
    });

    yield put(requestEnrollmentChangeMethodologySucceeded(data));
  } catch (e) {
    yield put(requestEnrollmentChangeMethodologyFailed());
  }
}

function* reqEnrollmentCheckMethodology(action) {
  const token = yield select(tokenSelector);
  const {
    idEnrollment,
    idTrainingPlan,
    idRoadMap,
    idAcademy,
  } = action;

  try {
    const data = yield call(api.enrollment.checkEnrollmentMethodology, {
      idEnrollment,
      idTrainingPlan,
      idRoadMap,
      idAcademy,
      token,
    });
    yield put(requestEnrollmentCheckMethodologySucceeded(data));
  } catch (e) {
    yield put(requestEnrollmentCheckMethodologyFailed());
  }
}

function* reqSetTransactionUpdatePrices(action) {
  const token = yield select(tokenSelector);
  const {
    newPrices,
  } = action;

  try {
    const data = yield call(api.enrollment.updatePrices, {
      bindingObject: newPrices,
      token,
    });
    yield put(setTransactionUpdatePricesSucceeded(data));
  } catch (e) {
    yield put(setTransactionUpdatePricesFailed());
  }
}

function* requestEnrollmmentServices(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
    origin,
  } = action;
  try {
    const data = yield call(api.enrollment.getServicesFiltered, {
      bindingObject,
      token,
    });
    yield put(requestEnrollmentServicesSucceeded(data, origin, bindingObject));
  } catch (e) {
    yield put(requestEnrollmentServicesFailed());
  }
}

function* reqRemoveEnrollmentJustificanteTransfe(action) {
  const token = yield select(tokenSelector);
  const {
    idEnrollment,
  } = action;
  try {
    const data = yield call(
      api.enrollment.deleteJustificanteTransfe, {
        token,
        idEnrollment,
      },
    );
    yield put(deleteEnrollmentJustificanteTransfeSucceeded(data));
  } catch (error) {
    yield put(deleteEnrollmentJustificanteTransfeFailed());
  }
}

function* reqUpdateEnrollmentJustificanteTransfe(action) {
  const token = yield select(tokenSelector);
  const { bindingObject } = action;
  const {
    idEnrollment,
    file,
  } = bindingObject;
  try {
    const data = yield call(
      api.enrollment.changeJustificanteTransfe, {
        token,
        idEnrollment,
        file,
      },
    );

    yield put(updateEnrollmentJustificanteTransfeSucceeded(data));
  } catch (error) {
    yield put(updateEnrollmentJustificanteTransfeFailed());
  }
}

function* reqEnrollmentTransferEspera(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.enrollment.getEnrollmentTransferPending, {
      bindingObject,
      token,
    });
    yield put(requestEnrollmentTransferEsperaSucceeded(data, bindingObject));
  } catch (e) {
    yield put(requestEnrollmentTransferEsperaFailed());
  }
}


export default [
  takeEvery(DOC_DOWNLOAD_REQUEST, reqDownloadDocument),
  takeEvery(REQUEST, reqEnrollments),
  takeEvery(FILTERS_REQUEST, reqFilters),
  takeEvery(FILTERS_REQUEST_HOLD, reqFilterHolds),
  takeEvery(REQUEST_DETAIL, reqEnrollmentsDetail),
  takeEvery(UPDATE, updateEnrollment),
  takeEvery(UPDATE_DOC, updateDocument),
  takeEvery(DOC_REQUEST, reqDocument),
  takeEvery(REQUEST_MODIFY, reqEnrollmentsModify),
  takeEvery(REMOVE, reqRemoveEnrollment),
  takeEvery(PAYMENT_MARK_AS_PENDING, reqPaymentMarkAsPending),
  takeEvery(PAYMENT_CHARGE, reqPaymentCharge),
  takeEvery(PAYMENT_MAIL, reqPaymentSendMail),
  takeEvery(PAYMENT_MARK_AS_PAID, reqPaymentMarkAsPaid),
  takeEvery(PAYMENT_UPDATE_DEADLINE, reqPaymentUpdateDeadline),
  takeEvery(CHECK_MODULE, reqCheckModule),
  takeEvery(CHECK_UNIT, reqCheckUnit),
  takeEvery(MOVE_TRANSACTION_MODULE, reqMoveTransactionModule),
  takeEvery(CREATE_TRANSACTION, reqCreateTransaction),
  takeEvery(REMOVE_TRANSACTION, reqRemoveTransaction),
  takeEvery(REQUEST_ENROLLMENT_EXPORT, reqEnrollmentExport),
  takeEvery(REQUEST_ENROLLMENT_GRADE_EXPORT, reqEnrollmentGradeExport),
  takeEvery(GET_TRANSACTION_PDF, reqGetTransactionPdf),
  takeEvery(REQUEST_PAGANTIS_ORDER, reqGetPagantisOrder),
  takeEvery(ENROLLMENT_TRANSACTION_TRANSFER_UPDATES, enrollmentTransactionTranserUpdates),
  takeEvery(REQUEST_GET_ALL_FOR_STUDENT, reqGetAllForStudents),
  takeEvery(REQUEST_ENROLLMENT_CHANGE_METHODOLOGY, reqEnrollmentChangeMethodology),
  takeEvery(REQUEST_ENROLLMENT_CHECK_METHODOLOGY, reqEnrollmentCheckMethodology),
  takeEvery(REQUEST_ENROLLMENT_HOLD, reqEnrollmentHold),
  takeEvery(REQUEST_ENROLLMENT_ACTIVATION, reqEnrollmentActivation),
  takeEvery(SET_TRANSACTION_UPDATE_PRICES, reqSetTransactionUpdatePrices),
  takeEvery(REQUEST_ENROLLMENT_SERVICES, requestEnrollmmentServices),
  takeEvery(DELETE_JUSTIFICANTE_TRANSFE, reqRemoveEnrollmentJustificanteTransfe),
  takeEvery(UPDATE_JUSTIFICANTE_TRANSFE, reqUpdateEnrollmentJustificanteTransfe),
  takeEvery(REQUEST_ENROLMENT_TRANSFER_ESPERA, reqEnrollmentTransferEspera),
];
